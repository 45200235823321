//gsap
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//activate ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export const SolutionAnime = () => {

  gsap.from('.bll-casestudy1_solutions-texts', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_solutions-texts',
      start: 'top 80%',
    },
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
  });

  gsap.from('.solutions-images-left', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_solutions-images',
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
      //markers: true
    },
    duration: 1,
    x: '-70%',
    ease: 'liner',
  });

  gsap.from('.solutions-images-right', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_solutions-images',
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
      //markers: true
    },
    duration: 1,
    x: '70%',
    ease: 'liner',
  });

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".bll-casestudy1_solutions",
      start: "top bottom",
      end: "bottom top",
      scrub: true,
    }
  });

  gsap.utils.toArray(".parallax2").forEach(layer => {
    const depth = layer.dataset.depth;
    const movement = (layer.offsetHeight * depth)
    tl.to(layer, { y: movement, ease: "none" }, 0)
  });

  gsap.from('.the-solutions li', {
    scrollTrigger: {
      trigger: '.the-solutions',
      start: 'top 60%',
    },
    duration: 0.5,
    y: 100,
    ease: 'back.out',
    autoAlpha: 0,
    stagger: 0.1,
  });

};