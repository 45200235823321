//gsap
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//activate ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

//parallax
const Parallax = require('parallax-js');

export const IntroAnime = () => {

  const introScene = document.getElementById('introScene');
  const introParallax = new Parallax(introScene);

  gsap.from('.bll-casestudy1_intro-texts', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_intro-texts',
      start: 'top 80%',
    },
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
  });

  const images = gsap.timeline({
    scrollTrigger: {
      trigger: ".bll-casestudy1_intro-images",
      start: 'top 60%',
    }
  });

  images.from('.intro-image1', {
    x: -150,
    rotate: -15,
    duration: 0.65,
    ease: 'back.out',
    autoAlpha: 0,
  });
  images.from('.intro-image2', {
    x: 150,
    rotate: 15,
    duration: 0.65,
    ease: 'back.out',
    autoAlpha: 0,
  }, "-=0.55");
  images.from('.intro-image3', {
    y: 150,
    duration: 0.65,
    ease: 'back.out',
    autoAlpha: 0,
  }, "-=0.55");

  // gsap.from('.intro-elements', {
  //   scrollTrigger: {
  //     trigger: '.bll-casestudy1_intro-images',
  //     start: 'top 60%',
  //   },
  //   duration: 0.65,
  //   scale: 0.5,
  //   ease: 'back.out',
  //   autoAlpha: 0,
  //   delay: 0.25,
  // });

  const quote = gsap.timeline({
    scrollTrigger: {
      trigger: ".bll-casestudy1_intro-quote",
      start: 'top 70%',
    }
  });

  quote.from('.bll-casestudy1_intro-quote .bll-blockquote_icon', {
    scale: 0.2,
    duration: 0.5,
    ease: 'back.out',
    autoAlpha: 0,
  });
  quote.from('.bll-casestudy1_intro-quote .bll-blockquote', {
    y: 50,
    duration: 0.5,
    ease: 'back.out',
    autoAlpha: 0,
  }, '-=0.2');

  gsap.from('.element-dots2', {
    scrollTrigger: {
      trigger: '.element-dots2',
      start: 'top 70%',
    },
    duration: 0.15,
    scale: 0,
    ease: 'back.out',
    stagger: 0.05,
    delay: 0.25,
  });

  gsap.set("#path3", {
    drawSVG: 0,
    stroke: "#7cccbe"
  });

  gsap.to("#path3", {
    scrollTrigger: {
      trigger: ".bll-casestudy1_intro-images",
      start: 'top 50%',
    },
    drawSVG: true,
    ease: "sine.in",
    duration: 1,
    delay: 0.25,
  });

};