//gsap
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//activate ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export const IncorporatingAnime = () => {

  gsap.from('.bll-casestudy1_incorporating-texts', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_incorporating-texts',
      start: 'top 70%',
    },
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
  });

  const images = gsap.timeline({
    scrollTrigger: {
      trigger: ".bll-casestudy1_incorporating-images",
      start: 'top 60%',
    }
  });

  images.from('.incorporating-image1', {
    x: -100,
    rotate: -10,
    duration: 0.65,
    ease: 'back.out',
    autoAlpha: 0,
  });
  images.from('.incorporating-image2', {
    x: 100,
    rotate: 10,
    duration: 0.65,
    ease: 'back.out',
    autoAlpha: 0,
  }, "-=0.5");
  images.from('.incorporating-image3', {
    x: -100,
    rotate: 10,
    duration: 0.65,
    ease: 'back.out',
    autoAlpha: 0,
  }, "-=0.5");
  images.from('.incorporating-image4', {
    x: 100,
    rotate: -10,
    duration: 0.65,
    ease: 'back.out',
    autoAlpha: 0,
  }, "-=0.5");


  gsap.from('.color:nth-child(even)', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_incorporating-colors',
      start: 'top 70%',
    },
    duration: 0.5,
    y: 100,
    ease: 'back.out',
    autoAlpha: 0,
    stagger: 0.15,
  });

  gsap.from('.color:nth-child(odd)', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_incorporating-colors',
      start: 'top 70%',
    },
    duration: 0.5,
    y: 100,
    ease: 'back.out',
    autoAlpha: 0,
    stagger: 0.15,
    delay: 0.15,
  });

  gsap.from('.bll-casestudy1_incorporating-modular .heading6b', {
    duration: 0.65,
    y: 80,
    ease: 'power4.out',
    autoAlpha: 0,
  });

  gsap.from('.modular-ui', {
    scrollTrigger: {
      trigger: '.modular-uis',
      start: 'top 70%',
      toggleActions: 'play none none none',
    },
    duration: 0.65,
    y: 80,
    ease: 'back.out',
    autoAlpha: 0,
    stagger: 0.10,
  });

  gsap.to('.incorporating-element1', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_incorporating',
      start: "top bottom",
      end: "bottom top",
      scrub: true,
    },
    y: 500,
    ease: "none"
  });

};