//gsap
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//activate ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export const ProblemAnime = () => {

  gsap.from('.bll-casestudy1_problem-texts', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_problem-texts',
      start: 'top 80%',
      end: 'bottom 60%',
    },
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
  });

  gsap.from('.problem-images-left', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_problem-images',
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
    },
    duration: 1,
    x: '-70%',
    ease: 'liner',
  });

  gsap.from('.problem-images-right', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_problem-images',
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
    },
    duration: 1,
    x: '70%',
    ease: 'liner',
  });

};