//gsap
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//activate ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export const OthersAnime = () => {

  gsap.from('.bll-casestudy1_others', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_others',
      start: 'top 80%',
    },
    duration: 1,
    ease: 'power4.out',
    autoAlpha: 0,
  });

};