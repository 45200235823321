//gsap
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//activate ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

//draw
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);

//parallax
const Parallax = require('parallax-js');

export const HeroAnime = () => {

  const heroScene = document.getElementById('heroScene');
  const heroParallax = new Parallax(heroScene);

  const hero = gsap.timeline({
    scrollTrigger: {
      trigger: ".bll-casestudy1_hero",
      start: 'top 50%',
    },
    delay: 0.5,
  });

  hero.from('.bll-casestudy1_hero-video', {
    duration: 1,
    scale: 1.2,
    ease: 'power4.out',
    autoAlpha: 0,
  });

  hero.from('.bll-casestudy1_hero-heading', {
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
  });

  hero.from('.bll-casestudy1_hero-subheading', {
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
  }, '-=0.55');

  hero.from('.bll-casestudy1_hero-infos', {
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
  }, '-=0.55');

  const images = gsap.timeline({
    scrollTrigger: {
      trigger: ".bll-casestudy1_hero-images",
      start: 'top 60%',
    }
  });

  images.from('.hero-image1', {
    x: -150,
    rotate: -15,
    duration: 0.65,
    ease: 'back.out',
    autoAlpha: 0,
  });
  images.from('.hero-image2', {
    x: 150,
    rotate: 15,
    duration: 0.65,
    ease: 'back.out',
    autoAlpha: 0,
  }, "-=0.55");
  images.from('.hero-image3', {
    y: 150,
    duration: 0.65,
    ease: 'back.out',
    autoAlpha: 0,
  }, "-=0.55");

  // gsap.from('.hero-elements', {
  //   scrollTrigger: {
  //     trigger: '.bll-casestudy1_hero-images',
  //     start: 'top 70%',
  //   },
  //   duration: 0.65,
  //   scale: 0.5,
  //   ease: 'back.out',
  //   autoAlpha: 0,
  //   delay: 0.25,
  // });

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".bll-casestudy1_intro",
      start: "top bottom",
      end: "bottom top",
      scrub: true,
    }
  });

  gsap.utils.toArray(".parallax").forEach(layer => {
    const depth = layer.dataset.depth;
    const movement = (layer.offsetHeight * depth)
    tl.to(layer, { y: movement, ease: "none" }, 0)
  });

  gsap.set("#path1", {
    drawSVG: 0,
    stroke: "#7CCCBE"
  });

  gsap.to("#path1", {
    scrollTrigger: {
      trigger: ".bll-casestudy1_hero-images",
      start: 'top 50%',
    },
    drawSVG: true,
    ease: "sine.in",
    duration: 1,
    delay: 0.25,
  });

  gsap.from('.element-dots1', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_hero-images',
      start: 'top 70%',
    },
    duration: 0.15,
    scale: 0,
    ease: 'back.out',
    stagger: 0.05,
    delay: 0.25,
  });

  gsap.set("#path2", {
    drawSVG: 0,
    stroke: "#beb1d7"
  });

  gsap.to("#path2", {
    scrollTrigger: {
      trigger: ".bll-casestudy1_hero-images",
      start: 'top 50%',
    },
    drawSVG: true,
    ease: "sine.in",
    duration: 1,
    delay: 0.25,
  });

};