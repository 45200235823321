//gsap
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//activate ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

//parallax
const Parallax = require('parallax-js');

export const ProcessAnime = () => {

  const processScene = document.getElementById('processScene');
  const processParallax = new Parallax(processScene);

  gsap.from('.bll-casestudy1_process-texts', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_process-texts',
      start: 'top 80%',
    },
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
  });

  gsap.from('.process-images', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_process-images',
      start: 'top 50%',
      toggleActions: 'play none none none',
    },
    duration: 0.65,
    y: 200,
    ease: 'back.out',
    autoAlpha: 0,
    stagger: 0.10,
  });

  // gsap.from('.process-elements', {
  //   scrollTrigger: {
  //     trigger: '.bll-casestudy1_process-images',
  //     start: 'top 60%',
  //     toggleActions: 'play none none none'
  //   },
  //   duration: 2,
  //   ease: 'power2.out',
  //   autoAlpha: 0,
  // });

  gsap.to('.process-element3', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_process-images',
      start: "top bottom",
      end: "bottom top",
      scrub: true,
    },
    y: 300,
    ease: "none"
  });

  gsap.set("#path4", {
    drawSVG: 0,
    stroke: "#7CCCBE"
  });

  gsap.to("#path4", {
    scrollTrigger: {
      trigger: ".bll-casestudy1_process-images",
      start: 'top 50%',
    },
    drawSVG: true,
    ease: "sine.in",
    duration: 1,
    delay: 0.25,
  });

  gsap.from('.element-dots3', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_process-images',
      start: 'top 70%',
    },
    duration: 0.15,
    scale: 0,
    ease: 'back.out',
    stagger: 0.05,
    delay: 0.25,
  });

};