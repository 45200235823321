//jquery
const jquery = require('jquery')
$ = window.$ = window.jQuery = jquery;

//nav
$(() => {
  $(window).on('scroll load', function () {
    var $wScroll = $(this).scrollTop();
    var $nav = $('.bll-nav');
    var $navInner = $('.bll-nav_inner');
    if ($wScroll >= 50) {
      $nav.addClass('--scrolled');
      $navInner.addClass('--scrolled');
    } else {
      $nav.removeClass('--scrolled');
      $navInner.removeClass('--scrolled');
    }
  });
});

//animations
import { HeroAnime } from "./animations/hero";
import { IntroAnime } from "./animations/intro";
import { ProblemAnime } from "./animations/problem";
import { ProcessAnime } from "./animations/process";
import { SolutionAnime } from "./animations/solution";
import { IncorporatingAnime } from "./animations/incorporating";
import { ResultsAnime } from "./animations/results";
import { OthersAnime } from "./animations/others";

document.addEventListener("DOMContentLoaded", function(event) {
  window.addEventListener("load", function(e) {
    $('body').removeClass('no-scroll');
    HeroAnime();
    IntroAnime();
    ProblemAnime();
    ProcessAnime();
    SolutionAnime();
    IncorporatingAnime();
    ResultsAnime();
    OthersAnime();
  }, false);
});
