//gsap
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//activate ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export const ResultsAnime = () => {

  gsap.from('.bll-casestudy1_results-texts', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_results-texts',
      start: 'top 70%',
    },
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
  });

  const quote = gsap.timeline({
    scrollTrigger: {
      trigger: ".bll-casestudy1_results-quote",
      start: 'top 70%',
    }
  });

  quote.from('.bll-casestudy1_results-quote .bll-blockquote_icon', {
    scale: 0.2,
    duration: 0.5,
    ease: 'back.out',
    autoAlpha: 0,
  });
  quote.from('.bll-casestudy1_results-quote .bll-blockquote', {
    y: 50,
    duration: 0.5,
    ease: 'back.out',
    autoAlpha: 0,
  }, '-=0.2');

  gsap.from('.bll-casestudy1_results-outcomes', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_results-outcomes',
      start: 'top 70%',
    },
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
  });

  gsap.from('.bll-casestudy1_results-recognition', {
    scrollTrigger: {
      trigger: '.bll-casestudy1_results-recognition',
      start: 'top 70%',
    },
    duration: 0.65,
    y: 100,
    ease: 'power4.out',
    autoAlpha: 0,
    delay: 0.1
  });

  gsap.from('.funding', {
    scrollTrigger: {
      trigger: '.funding',
      start: 'top 70%',
    },
    textContent: 0,
    duration: 2,
    ease: 'power4.out',
    delay: 0.1,
    snap: { textContent: 1 },
    stagger: 1,
  });
  
  gsap.from('.customers', {
    scrollTrigger: {
      trigger: '.customers',
      start: 'top 70%',
    },
    textContent: 0,
    duration: 2,
    ease: 'power4.out',
    delay: 0.1,
    snap: { textContent: 1 },
    stagger: 1,
  });

};